import firebase from 'firebase';
import React, { FC } from 'react';
import voltlinesLogo from '../assets/images/voltlines@logo.png';
import googleLogo from '../assets/images/google@logo.svg';
import backgroundImage from '../assets/images/login-bg.webp';

interface LoginProps {
  loading?: boolean
}

const Login: FC<LoginProps> = ({ loading }) => {
  const login = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithRedirect(provider);
  };
  return (
    <div className="h-screen w-screen bg-center bg-cover flex" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="bg-white m-auto py-4 px-8 text-center bg-opacity-70 rounded-lg">
        <div className="w-full flex justify-center items-center pb-2">
          <img height={52.09} width={72} src={voltlinesLogo} alt="Volt Lines" />
        </div>
        <h1 className="text-2xl">
          Volt
          {' '}
          <span className="text-primary">Lines</span>
          {' '}
          | ProdTech
        </h1>
        <button
          type="button"
          disabled={loading}
          className="py-3 mt-4 px-4 w-11/12 bg-white shadow-lg text-gray-700 rounded-full flex"
          onClick={login}
        >
          {
            loading ? (
              <div className="w-full">
                <span className="text-center">Loading...</span>
              </div>
            ) : (
              <>
                <img width={24} height={24} src={googleLogo} alt="Google" />
                <div className="w-full">
                  <span className="text-center">Login with Google</span>
                </div>
              </>
            )
          }
        </button>
      </div>
    </div>
  );
};

export default Login;
