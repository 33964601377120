import React, { FC } from 'react';
import firebase from 'firebase';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Header from './Header';
import Handbook from './Handbook';

interface HomeProps {
    user: firebase.User
}

const Home: FC<HomeProps> = ({ user }) => (
  <Router>
    <Header user={user} />
    <div style={{ height: 'calc(100vh - 64px)' }} className="pt-4">
      <Switch>
        <Route exact path="/">
          <Handbook />
        </Route>
      </Switch>
    </div>
  </Router>
);

export default Home;
