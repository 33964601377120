import React from 'react';
import ReactDOM from 'react-dom';
import 'firebase/auth';
import { FirebaseAppProvider } from 'reactfire';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import firebaseConfig from './firebase.config';

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();
