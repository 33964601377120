import React, { FC } from 'react';
import { useSigninCheck } from 'reactfire';
import firebase from 'firebase';
import Login from './components/Login';
import Home from './components/Home';

const App: FC = () => {
  const { status, data: signinResult } = useSigninCheck();

  if (status === 'loading') {
    return <Login loading />;
  }

  const { signedIn } = signinResult;
  const user = signinResult.user as firebase.User;

  if (signedIn) {
    return <Home user={user} />;
  }
  return <Login />;
};

export default App;
