const firebaseConfig = {
  apiKey: 'AIzaSyCEW25syLR0jrHNitCSfv4D1pXaWLSONYY',
  authDomain: 'prod-tech.firebaseapp.com',
  databaseURL: 'https://prod-tech.firebaseio.com',
  projectId: 'prod-tech',
  storageBucket: 'prod-tech.appspot.com',
  messagingSenderId: '371074211782',
  appId: '1:371074211782:web:95748cb26677525f2ddcc7',
  measurementId: 'G-Y1X1FJMN4F',
};

export default firebaseConfig;
